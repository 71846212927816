import React, { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";

import Layout from "../shared/layout";
import SEO from "../shared/seo";
import { Map } from "../shared/map";

// TODO CSS modules
import "./contact.scss";

const ContactPage: FunctionComponent = () => {
  const { formatMessage, locale } = useIntl();

  return (
    <Layout>
      <SEO
        title={formatMessage({ id: "page.contact" })}
        description={formatMessage({ id: "contact.headline" })}
        lang={locale}
      />
      <section className="map">
        <Map />
      </section>

      <div className="cs_container">
        <div className="cs_row">
          <div className="cs_col">
            <address className="contact-info">
              <h1 className="text-center">
                <FormattedMessage id="page.contact" />
              </h1>
              <p>
                <FormattedMessage id="contact.headline" />
              </p>
              <ul className="information-list">
                <li>
                  <FaMapMarkerAlt />
                  <FormattedMessage id="contact.street" tagName="span" />
                  <FormattedMessage id="contact.city" tagName="span" />
                  <FormattedMessage id="contact.country" tagName="span" />
                </li>
                <li>
                  <FaPhone />
                  <span className="phone-numbers">
                    <div>
                      <FormattedMessage id="contact.phone" />
                      <a href="tel:+48227738925">+48&nbsp;(0)22&nbsp;773-89-25</a>
                    </div>
                    <div>
                      <a href="tel:+480227604407">+48&nbsp;(0)22&nbsp;760-44-07</a>
                    </div>
                    <div className="fax">
                      <FormattedMessage id="contact.fax" />
                      <a href="tel:+480227738955">+48&nbsp;(0)22&nbsp;773-89-55</a>
                    </div>
                  </span>
                </li>
                <li>
                  <FaEnvelope />
                  <span className="email">
                    <a href="mailto:biuro@silico.pl">biuro@silico.pl</a>
                  </span>
                </li>
              </ul>
            </address>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
