import React, { FunctionComponent } from "react";

// TODO CSS modules
import "./map.scss";

const API_KEY = "AIzaSyAmyGdWT3r0vndYAo6hu7YizgoRb1AYITc";
const SILICO_PLACE_ID = "ChIJ6YLoGmLWHkcRFGxhQ37Q5_0";
const GOOGLE_MAPS_URL = "https://www.google.com/maps/embed/v1/place";

export const Map: FunctionComponent = () => {
  const src = `${GOOGLE_MAPS_URL}?key=${API_KEY}&q=place_id:${SILICO_PLACE_ID}&zoom=11`;
  return <iframe frameBorder="0" src={src} title="map"/>;
};
